import React from "react"
// components
import Layout from "../../components/layout"
import Form from "../../components/organisms/form"
import SEO from "../../components/seo"

const Application = ({ location }) => {
  const form = [
    {
      tableHeader: {
        id: "name",
        label: "お名前",
        required: true
      },
      tableData: {
        type: "text",
        name: "name",
        id: "name",
        placeholder: "例）中野 太郎",
        required: true
      }
    },
    {
      tableHeader: {
        id: "katakana",
        label: "フリガナ",
        required: true
      },
      tableData: {
        type: "text",
        name: "katakana",
        id: "katakana",
        placeholder: "例）ナカノ タロウ",
        required: true
      }
    },
    {
      tableHeader: {
        id: "age",
        label: "年齢",
        required: true
      },
      tableData: {
        name: "age",
        id: "age",
        required: true,
        options: new Array(60 - 16 + 1).fill().map((_, index) => `${index + 16}歳`)
      }
    },
    {
      tableHeader: {
        label: "性別",
        required: true
      },
      tableData: {
        type: "radio",
        name: "性別",
        required: true,
        labels: [
          "男性",
          "女性"
        ]
      }
    },
    {
      tableHeader: {
        id: "email",
        label: "メールアドレス",
        required: true
      },
      tableData: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: "例）marutomo@mercury.dti.ne.jp",
        required: true
      }
    },
    {
      tableHeader: {
        id: "tel",
        label: "電話番号"
      },
      tableData: {
        type: "tel",
        name: "tel",
        id: "tel",
        placeholder: "例）03-5380-0065"
      }
    },
    {
      tableHeader: {
        id: "address",
        label: "住所"
      },
      tableData: {
        type: "text",
        name: "address",
        id: "address",
        placeholder: "例）東京都中野区新井1-11-9 5階"
      }
    },
    {
      tableHeader: {
        label: "ご希望の連絡方法",
        required: true
      },
      tableData: {
        type: "radio",
        name: "ご希望の連絡方法",
        required: true,
        labels: [
          "メール",
          "電話"
        ]
      }
    },
    {
      tableHeader: {
        id: "message",
        label: "自己PR"
      },
      tableData: {
        name: "message",
        id: "message"
      }
    }
  ]
  return (
    <Layout location={location}>
      <Form
        form={form}
        padding="15px 0 25px"
        name="application"
      />
    </Layout>
  )
}

export default Application

export const Head = ({ location }) => (
  <SEO
    title="応募フォーム | 採用情報"
    description="株式会社マルトモの採用情報ページです。"
    pathname={location.pathname}
  />
)
